<template>
    <div>
      <div class="nav">
      <nav
        class="navbar navbar-expand-lg navbar-light fixed-top"
        style="
          margin: auto;
          position: sticky;
          margin-top: -65px;
          width: -moz-available;
        "
      >
        <div class="container p-2" style="margin: auto">
          <a class="navbar-brand" href="/">
            <img class="img-fluid d-block" src="/img/logo.png" />
          </a>
          <button
            class="navbar-toggler navbar-toggler-right border-0"
            type="button"
            data-toggle="collapse"
            data-target="#navbar5"
          >
            <span class="navbar-toggler-icon"></span>
          </button>

          <ul class="navbar-nav ml-auto text-center">
            <li class="nav-item">
              <a class="nav-link" href="/somos" style="color: #4b5359">Quem somos</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/experimente" style="color: #4b5359"
                >Experimente</a
              >
            </li>
          </ul>
        </div>
      </nav>
    </div>

   <router-view></router-view>

   <div class="footer1 py-5" style="">
    <div class="container">
      <div class="row">
        <div class="p-3 col-lg-9 col-md-6 col-12" style="">
          <h5> <b>Termo de Renegociação</b></h5>
          <ul class="list-unstyled">
              <li><a class="btn btn-link" href="https://www.realizesolucoesfinanceiras.com.br/" style="color: #cc0000">Home</a></li>
              <li><a class="btn btn-link" href="https://www.realizesolucoesfinanceiras.com.br/" style="color: #cc0000">Quem somos</a></li>
              <li><a class="btn btn-link" href="https://www.realizesolucoesfinanceiras.com.br/" style="color: #cc0000">Experimente</a></li>
              <li><a class="btn btn-link" target="_blank" href="https://www.realizesolucoesfinanceiras.com.br/images/relatorio-governanca/download/2d74c7a135c98327b39e82f8638a6437.pdf" style="color: #cc0000">Termos de uso</a></li>
              <li><a class="btn btn-link" target="_blank" href="https://www.realizesolucoesfinanceiras.com.br/images/relatorio-governanca/download/2d74c7a135c98327b39e82f8638a6437.pdf" style="color: #cc0000">Política de privacidade</a></li>
              <li><a class="btn btn-link" target="_blank" href="https://www.realizesolucoesfinanceiras.com.br/images/relatorio-governanca/download/2d74c7a135c98327b39e82f8638a6437.pdf" style="color: #cc0000">Política de cookies</a></li>
              <!-- <router-link
                v-for="link in footerlinks"
                :key="link.text"
                :to="{
                  name: link.route,
                  query: {
                    title: link.text,
                  },
                }"
                ><li>
                  <a style="color: #cc0000">{{ link.text }}</a>
                </li></router-link
              > -->
            </ul>
        </div>
        <div class="p-3 col-lg-3 col-md-6" style="">
          <p class="mb-0"> Fale conosco:</p>
          <a class="btn btn-link" href="#"><i class="fa fa-phone fa-fw mx-1 fa-lg"></i>(14) 3235-0900</a><a class="btn btn-link" href="#"><i class="fab fa-whatsapp fa-fw mx-1 fa-lg"></i>(11) 3230 -3765</a><a class="btn btn-link" href="mailto:contato@PG Mais.com.br" target="_blank"><i class="fa fa-envelope fa-fw mx-1 fa-lg"></i>contato@PG Mais.com.br</a>
          <hr><a class="btn btn-link" href="https://www.facebook.com/LojasRenner" target="_blank"><i class="fab fa-facebook-square fa-fw mx-1 fa-lg"></i></a>
          <a class="btn btn-link" href="https://www.instagram.com/lojasrenner/" target="_blank"><i class="fab fa-instagram fa-fw mx-1 fa-lg"></i></a>
          <a class="btn btn-link" href="https://www.linkedin.com/company/lojas-renner" target="_blank"><i class="fab fa-linkedin fa-fw mx-1 fa-lg"></i></a>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 text-center">
          <p class="mb-0 mt-2 small">Realize Crédito, Financiamento e Investimento S.A. – CNPJ 27.351.731/0001-38 <br>
                                     Avenida Dolores Alcaraz Caldas, 90 – 9º e 10° andares – CEP 90110-180 – Porto Alegre/RS</p>
        </div>
      </div>
    </div>
    <!-- <div class="nav">
      <a class="nav-link wts bg-success" href="#" target="_blank">
        <i class="fa fa-2x fa-whatsapp text-light"></i>
      </a>
    </div> -->
  </div>

   <!-- <div class="nav">
      <a class="nav-link wts bg-success" @click="openWhats()" target="_blank" style="cursor:pointer">
        <i class="fab fa-2x fa-whatsapp text-light"></i>
      </a>
    </div> -->
  </div>
    

</template>

<script>
import { mapActions,mapState } from "vuex";

export default {
    name: "Landing",
    components: {
    },
    data: () => ({
       authlinks: [
            { icon: '', text: 'Início', route: '/dashboard' },
        ],
        hrefwhatsapp:'https://api.whatsapp.com/send?l=pt&phone=551132303765',
        footerlinks: [
            { icon: '', text: 'Quem Somos', route: 'somos'},
            { icon: '', text: 'Experimente', route: 'experimente'},
            { icon: '', text: 'Termos de uso', route: 'termos'},
            { icon: '', text: 'Política de privacidade', route: 'sobre'},
            { icon: '', text: 'Política de cookies', route: 'sobre'}
        ]
    }),
    methods: {
        ...mapActions('auth', ['ActionDoLogout','ActionMenuLinks','ActionGetUser','ActionGetWhatsText']),
        ...mapActions('meusBoletos', ['ActionSendAccessLogWithoutToken']),
        // async openWhats () {
        //   if(this.user != null){
        //     var whats = await this.ActionGetWhatsText();
        //     this.hrefwhatsapp = this.hrefwhatsapp + '&text='+ whats[0];
        //   }else{
        //     console.log('registrando log...');
        //      await this.ActionSendAccessLogWithoutToken({
        //       ValueId : 16
        //     });
        //     this.hrefwhatsapp = this.hrefwhatsapp + '&text=Olá, não encontrei meus dados podem me ajudar ?';
            
        //     console.log('log registrado.');
        //   }           

        //    window.open(this.hrefwhatsapp, "_blank");
        // }
    },
    async created() {
      if(this.user != null){
        //await this.ActionGetUser();
          this.user.Menus.forEach(menuId => {
              switch(parseInt(menuId)){
                  case 2:
                      this.authlinks.push({ icon: '', text: 'Contratos Negociáveis', route: '/dashboard/minhas-dividas' });
                      break;
                  case 1:
                      this.authlinks.push({ icon: '', text: 'Boletos Ativos', route: '/dashboard/meus-boletos' });
                      break;
                  case 3:
                      this.authlinks.push({ icon: '', text: 'Acordos', route: '/dashboard/meus-acordos' });
                      break;
              }
          });
      }
          
    },
    computed:{ 
        ...mapState('auth', ['user']),
        isLoggedIn(){ 
            return this.$store.getters['auth/isLoggedIn']
        } 
    }
};
</script>
