<template>
  <div id="lp1">
    <div class="container">
      <div class="row">
        <div class="mx-auto bg-white shadow rounded p-5 col-10 lpp col-md-4"  v-if="!sendCode">
          <h4>TERMO DE RENEGOCIAÇÃO</h4>
          <p>Preencha os campos abaixo para proceder com sua negociação</p>
          <form v-on:submit="doLogin" v-on:submit.prevent id="form">
            <div class="form-group">
              <input
                type="text"
                v-mask="'###.###.###-##'"
                class="form-control"
                placeholder="Seu CPF ou CNPJ"
                id="form9"
                v-model="form.accessdoc"
              />
            </div>
            <div class="form-group mb-3">
              <input
                type="password"
                class="form-control"
                placeholder="Código de acesso"
                id="form10"
                v-model="form.accesscode"
              />
              <small class="form-text text-muted text-right">
                <a href="#" v-if="!loading" class="float-right mt-4">
                  <button @click="notCode()">Quero receber o código</button></a
                >
              </small>
            </div>
            <button
              type="submit"
              v-if="!loading"
              class="btn btn-secondary btn-lg mt-3 text-white"
              style="background-color: #d71920; border-color: #d71920"
            >
              Acessar agora
            </button>
          </form>
          <ProgressLoading v-bind:loading="loading" />
        </div>

        <div
          class="mx-auto bg-white shadow rounded p-5 col-10 lpp col-md-4"
          v-if="sendCode"
        >
          <h4>Receber código de acesso</h4>
          <p>Informe por qual meio quer receber seu código de acesso</p>
          <form>
            <div class="form-group">
              <input
                type="text"
                class="form-control mb-4"
                disabled
                placeholder="Seu CPF ou CNPJ"
                id="form9"
                required
                v-model="form.accessdoc"
              />
              <div class="form-row px-1">
                <div class="col-12">
                  <p>Escolha como deseja receber o código</p>
                </div>
                <div
                  class="custom-control custom-radio custom-control-inline mr-5"
                >
                  <input
                    type="radio"
                    value="email"
                    class="custom-control-input"
                    id="email-r"
                    name="EmailOrPhone"
                    v-model="EmailOrPhone"
                    @click="(email = true), (sms = false)"
                  />
                  <label class="custom-control-label" for="email-r"
                    >Por E-mail</label
                  >
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    id="sms-r"
                    value="sms"
                    name="EmailOrPhone"
                    class="custom-control-input"
                    v-model="EmailOrPhone"
                    @click="(sms = true), (email = false)"
                  />
                  <label class="custom-control-label" for="sms-r"
                    >Por SMS</label
                  >
                </div>
                <input
                  disabled
                  v-if="email"
                  type="email"
                  class="form-control my-4"
                  placeholder="Email"
                  id="form9"
                  v-model="emailDebtor"
                />
                <input
                  disabled
                  v-if="sms"
                  type="tel"
                  class="form-control my-4"
                  placeholder="Telefone"
                  id="form9"
                  v-model="smsDebtor"
                  v-mask="['(##) # ####-####']"
                />
              </div>
            </div>
            <div class="form-group mb-3">
              <small class="form-text text-muted text-right float-right">
                <a href="#" @click="notCode()">Lembrei meu código</a>
              </small>
            </div>
          </form>
          <a class="btn btn-secondary" href="#" @click="sendPromoCode()"
            >Receber código</a
          >
          <ProgressLoading v-bind:loading="loading" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { mask } from "vue-the-mask";

import ProgressLoading from "../../modules/auth/components/ProgressLoading.vue";

export default {
  directives: { mask },
  name: "LoginLayout",
  components: {
    ProgressLoading,
  },
  data: () => ({
    imageBackground: localStorage.heroImg,

    form: {
      accessdoc: null,
      accesscode: null,
    },
    error: {
      status: null,
      message: null,
    },
    loading: false,
    initializing: true,
    thereIsCpf: false,
    promoCode: null,
    sendCode: false,
    EmailOrPhone: "email",
    email: true,
    sms: false,
    code: false,
    emailDebtor: null,
    smsDebtor: null,
    infoDebtor: null,
  }),
  methods: {
    ...mapActions("auth", [
      "ActionDoLogin",
      "ActionDoRegisterSession",
      "ActionSetUser",
      "ActionGetContactsByDocNumber",
      "ActionSendPromoCode",
      "ActionGetIPAddress",
      "ActionSetCurrentForm",
    ]),

    async validateCpf() {
      this.loading = true;

      if (this.form.accessdoc === null || this.form.accessdoc === "") {
        this.loading = false;
        return;
      }

      var resp = await this.ActionGetContactsByDocNumber({
        DocNumber:
          this.form.accessdoc != null
            ? this.form.accessdoc
                .replace(".", "")
                .replace(".", "")
                .replace("-", "")
            : "",
      });

      if (resp.status == 404) {
        this.flashMessage.show({
          timeout: 1000,
          status: "error",
          title: "Ocorreu um erro",
          message: "Nenhum usuário foi encontrado!",
        });
        this.loading = false;
        return;
      }

      this.emailDebtor =
        resp.data.results.Emails.length > 0 ? resp.data.results.Emails[0] : "";
      this.smsDebtor =
        resp.data.results.PhoneNumbers.length > 0
          ? resp.data.results.PhoneNumbers[0]
          : "";
      this.infoDebtor = resp.data.results;

      this.ActionSetUser(resp.data.results);
      this.promoCode = resp.data.results.PromoCode;
      this.loading = false;
      this.thereIsCpf = true;
    },

    async doLogin() {
      // this.promoCodeErrors.pop();
      // this.$v.$touch();
      this.loading = true;

      if (this.form.accessdoc === null || this.form.accessdoc === "") {
        this.loading = false;
        return;
      }

      var resp = await this.ActionGetContactsByDocNumber({
        DocNumber:
          this.form.accessdoc != null
            ? this.form.accessdoc
                .replace(".", "")
                .replace(".", "")
                .replace("-", "")
            : "",
      });

      if (resp.status == 404) {
        this.flashMessage.show({
          timeout: 1000,
          status: "error",
          title: "Ocorreu um erro",
          message: "Nenhum usuário foi encontrado!",
        });
        this.loading = false;
        return;
      }

      this.emailDebtor =
        resp.data.results.Emails.length > 0 ? resp.data.results.Emails[0] : "";
      this.smsDebtor =
        resp.data.results.PhoneNumbers.length > 0
          ? resp.data.results.PhoneNumbers[0]
          : "";
      this.infoDebtor = resp.data.results;

      let debtorId = this.infoDebtor.DebtorID;
      let promoCode = this.form.accesscode;
      var ipmachine = await this.ActionGetIPAddress();

      try {
        await this.ActionDoLogin({
          DebtorID: debtorId,
          PromoCode: promoCode,
          device_name: process.env.VUE_APP_API_DEVICE,
          IP: ipmachine,
          OS: navigator.platform,
        });
        this.loading = false;
        location.href = "/";
        // this.$router.push({ path: "/dashboard" });
      } catch (error) {
        this.flashMessage.show({
          timeout: 300,
          status: "error",
          position: "top right",
          title: "Erro",
          message:
            "ocorreu um erro ao autenticar, promocode incorreto ou inválido",
        });
        this.loading = false;
        return;
      }
    },

    async sendPromoCode() {
      this.loading = true;
      try {
        if (!this.form.accessdoc) {
          this.flashMessage.show({
            timeout: 1000,
            status: "error",
            title: "Ocorreu um erro",
            message: "Preencha o CPF",
          });
          this.loading = false;
          return;
        }

        await this.validateCpf(this.form.accessdoc);

        if (this.email) {
          if (!this.emailDebtor || this.emailDebtor == null) {
            this.flashMessage.show({
              timeout: 1000,
              status: "error",
              title: "Ocorreu um erro",
              message: "Não há nenhum email cadastrado.",
            });
            this.loading = false;
            return;
          }

          var resp = await this.ActionSendPromoCode({
            DebtorId: this.infoDebtor.DebtorID,
            Email: this.emailDebtor,
          });

          this.loading = false;

          if (resp.status == 200) {
            this.flashMessage.show({
              timeout: 1000,
              status: "success",
              title: "Sucesso",
              blockClass: "custom-block-class",
              message: "Código de acesso enviado por e-mail com sucesso",
            });
          }
          this.loading = false;
          this.backCode();
          this.back();
        } else {
          this.loading = true;

          if (!this.smsDebtor || this.smsDebtor == null) {
            this.flashMessage.show({
              timeout: 1000,
              status: "error",
              title: "Ocorreu um erro",
              message: "Não há nenhum telefone cadastrado.",
            });
            this.loading = false;
            return;
          }
          var resp1 = await this.ActionSendPromoCode({
            DebtorId: this.infoDebtor.DebtorID,
            PhoneNumber: this.smsDebtor
              .replace("(", "")
              .replace(")", "")
              .replace(" ", "")
              .replace(" ", "")
              .replace("-", ""),
          });

          if (resp1.status == 200) {
            this.flashMessage.show({
              timeout: 1000,
              status: "success",
              title: "Sucesso",
              blockClass: "custom-block-class",
              message: "Código de acesso enviado por SMS com sucesso",
            });
          }
          this.loading = false;
          this.backCode();
          this.back();
        }
      } catch (error) {
        this.flashMessage.show({
          timeout: 1000,
          status: "error",
          title: "Ocorreu um erro",
          message: error.message,
        });
        this.loading = false;
      }
    },

    async notCode() {
      this.emailDebtor = null;
      this.smsDebtor = null;
      this.infoDebtor = null;

      if (this.form.accessdoc === null || this.form.accessdoc === "") {
        this.loading = false;
        this.flashMessage.show({
          timeout: 1000,
          status: "error",
          title: "Erro",
          message: "Informe um CPF",
        });
        return;
      }

      if (!this.sendCode) {
        await this.validateCpf(this.form.accessdoc);
        if (!this.infoDebtor) {
          return;
        } else {
          this.sendCode = true;
        }
        this.loading = false;
      } else {
        this.sendCode = false;
      }
    },

    back() {
      this.thereIsCpf = false;
    },

    backCode() {
      this.sendCode = false;
      this.code = true;
      if (!this.back) {
        this.back = true;
      } else {
        this.back == false;
      }
    },
  },

  async created() {
    await this.ActionDoRegisterSession();
    var urlPath = this.$route.path;
    this.initializing = false;
    var paths = urlPath.split("/");

    this.form.accesscode = paths[paths.length - 1];
    if (urlPath.includes("acessobf")) {
      this.imageBackground = "/img/lp1.jpg";

      var head = document.getElementsByTagName("head")[0];

      var linkSCss = "/acesso/themebf.css";

      var link = document.createElement("link");
      link.rel = "stylesheet";
      link.type = "text/css";
      link.href = linkSCss;
      link.media = "all";

      head.appendChild(link);
    }
  },
  computed: {
    ...mapState("auth", ["user"]),
    isLoggedIn() {
      return this.$store.getters["auth/isLoggedIn"];
    },
    classMessage: function () {
      return {
        "text-danger": this.error.status == "ERROR",
        "text-muted": !this.error.status == "ERROR",
      };
    },
  },
};
</script>
