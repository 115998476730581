<template>
  <div>
    <div class="nav">
      <nav
        class="navbar navbar-expand-lg navbar-light fixed-top"
        style="
          margin: auto;
          position: sticky;
          margin-top: -65px;
          width: 60%;
        "
      >
        <div class="container" style="margin: auto">
          <a class="navbar-brand" href="/">
            <img class="img-fluid d-block" src="img/logo.png" />
          </a>
          <button
            class="navbar-toggler navbar-toggler-right border-0"
            type="button"
            data-toggle="collapse"
            data-target="#navbar5"
          >
            <span class="navbar-toggler-icon"></span>
          </button>

          <ul class="navbar-nav ml-auto text-center">
            <li class="nav-item">
              <a class="nav-link" href="/somos" style="color: #4b5359">Quem somos</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/experimente" style="color: #4b5359"
                >Experimente</a
              >
            </li>
          </ul>
        </div>
      </nav>
    </div>

    <flash-message
      transitionIn="animated swing"
      class="alert-system"
    ></flash-message>
    <Login height="100%"></Login>

    <div class="footer1 py-5" style="">
    <div class="container">
      <div class="row" style="justify-content: center;">
        <div class="p-3 col-lg-6 col-12 col-md-6" style="">
          <h5> <b>Termo de Renegociação</b></h5>
          <ul class="list-unstyled">
              <li><a class="btn btn-link" href="https://www.realizesolucoesfinanceiras.com.br/" style="color: #cc0000">Home</a></li>
              <li><a class="btn btn-link" href="https://www.realizesolucoesfinanceiras.com.br/" style="color: #cc0000">Quem somos</a></li>
              <li><a class="btn btn-link" href="https://www.realizesolucoesfinanceiras.com.br/" style="color: #cc0000">Experimente</a></li>
              <li><a class="btn btn-link" target="_blank" href="https://www.realizesolucoesfinanceiras.com.br/images/relatorio-governanca/download/2d74c7a135c98327b39e82f8638a6437.pdf" style="color: #cc0000">Termos de uso</a></li>
              <li><a class="btn btn-link" target="_blank" href="https://www.realizesolucoesfinanceiras.com.br/images/relatorio-governanca/download/2d74c7a135c98327b39e82f8638a6437.pdf" style="color: #cc0000">Política de privacidade</a></li>
              <li><a class="btn btn-link" target="_blank" href="https://www.realizesolucoesfinanceiras.com.br/images/relatorio-governanca/download/2d74c7a135c98327b39e82f8638a6437.pdf" style="color: #cc0000">Política de cookies</a></li>
              <!-- <router-link
                v-for="link in footerlinks"
                :key="link.text"
                :to="{
                  name: link.route,
                  query: {
                    title: link.text,
                  },
                }"
                ><li>
                  <a style="color: #cc0000">{{ link.text }}</a>
                </li></router-link
              > -->
            </ul>
        </div>
        <div class="p-3 col-lg-2 col-md-6" style="">
          <p class="mb-0"> Fale conosco:</p>
          <a class="btn btn-link" href="#"><i class="fa fa-phone fa-fw mx-1 fa-lg"></i>3003-6610</a><br>
          <a class="btn btn-link" href="#" style="pointer-events: none;">Horário de atendimento:</a><br>
          <a class="btn btn-link" href="#" style="pointer-events: none;">Em dias úteis, das 8h às 20h</a><br>
          <!-- <a class="btn btn-link" href="#"><i class="fab fa-whatsapp fa-fw mx-1 fa-lg"></i>(00) 0000 - 0000</a><br> -->
          <!-- <a class="btn btn-link" href="#" target="_blank"><i class="fa fa-envelope fa-fw mx-1 fa-lg"></i>email@empresa.com</a> -->

          <hr><a class="btn btn-link" href="https://www.facebook.com/LojasRenner" target="_blank"><i class="fab fa-facebook-square fa-fw mx-1 fa-lg"></i></a>
          <a class="btn btn-link" href="https://www.instagram.com/lojasrenner/" target="_blank"><i class="fab fa-instagram fa-fw mx-1 fa-lg"></i></a>
          <a class="btn btn-link" href="https://www.linkedin.com/company/lojas-renner" target="_blank"><i class="fab fa-linkedin fa-fw mx-1 fa-lg"></i></a>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 text-center">
          <p class="mb-0 mt-2 small">Realize Crédito, Financiamento e Investimento S.A. – CNPJ 27.351.731/0001-38 <br>
                                     Avenida Dolores Alcaraz Caldas, 90 – 9º e 10° andares – CEP 90110-180 – Porto Alegre/RS</p>
        </div>
      </div>
    </div>
    <!-- <div class="nav">
      <a class="nav-link wts bg-success" href="#" target="_blank">
        <i class="fa fa-2x fa-whatsapp text-light"></i>
      </a>
    </div> -->
  </div>
    <!-- <div class="nav">
      <a
        class="nav-link wts bg-success"
        @click="openWhats()"
        target="_blank"
        style="cursor: pointer"
      >
        <i class="fab fa-2x fa-whatsapp text-light"></i>
      </a>
    </div> -->
  </div>
</template>

<script>
import { mapActions } from "vuex";

import Login from "@/modules/auth/Login";
export default {
  name: "Home",
  components: {
    Login,
  },
  data: () => ({
    hrefwhatsapp: "https://api.whatsapp.com/send?l=pt&phone=551132303765",
    footerlinks: [
      { icon: "", text: "Quem Somos", route: "https://www.realizesolucoesfinanceiras.com.br" },
      { icon: "", text: "Experimente", route: "https://www.realizesolucoesfinanceiras.com.br" },
      {
        icon: "",
        text: "Termos de uso",
        route: "termos",
        params: "Termos de uso",
      },
      {
        icon: "",
        text: "Política de privacidade",
        route: "sobre",
        params: "Política de privacidade",
      },
      {
        icon: "",
        text: "Política de cookies",
        route: "sobre",
        params: "Política de cookies",
      },
    ],
  }),
  methods: {
    ...mapActions("meusBoletos", ["ActionSendAccessLogWithoutToken"]),
    // async openWhats() {
    //   if (this.user != null) {
    //     var whats = await this.ActionGetWhatsText();
    //     this.hrefwhatsapp = this.hrefwhatsapp + "&text=" + whats[0];
    //   } else {
    //     console.log("registrando log...");
    //     await this.ActionSendAccessLogWithoutToken({
    //       ValueId: 16,
    //     });
    //     this.hrefwhatsapp =
    //       this.hrefwhatsapp +
    //       "&text=Olá, não encontrei meus dados podem me ajudar ?";

    //     var document = window.document.getElementsByName("docnumber")[0].value;

    //     if (document != "" && document != null)
    //       this.hrefwhatsapp =
    //         this.hrefwhatsapp + " Meu documento é : " + document;

    //     console.log("log registrado.");
    //   }

    //   window.open(this.hrefwhatsapp, "_blank");
    // },
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters["auth/isLoggedIn"];
    },
  },
};
</script>
